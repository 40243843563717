import React, {useEffect} from 'react'
import {Link} from 'react-router-dom'
import "./OffresAbonnement.css"
import Illustration1 from "./Illustration1.svg"
import Illustration2 from "./Illustration2.svg"
import Illustration3 from "./Illustration3.svg"
// import Illustration4 from "./Illustration4.svg"
// import Illustration5 from "./Illustration5.svg"
import WatchIcon from "./watch-svgrepo-com.svg"

export default function OffresAbonnement() {
    useEffect(() => {
        document.title = "Offres & Abonnement - Azoya SPA Vaux";
    }, [])

    return (
        <div className='OffresAbonnement'>
            <div className="slide" id="slide1">
                <div className="image-container">
                    <h1>Nous vous proposons des concepts <em>adaptés</em> à vos besoins</h1>
                    <img src={Illustration1} alt="Illustration"/>
                </div>
            </div>
            <div className="slide" id="slide5">
                <img src={WatchIcon} alt="Illustration promotion"/>
                <div className="text-container">
                    <h1>Offre d'Avril <em>Cure minceur & LPG</em> !</h1>
                    <table border="1">
                        <tr>
                            <th>LPG</th>
                            <th>Corps</th>
                            <th>1 zone</th>
                            <th>Visage</th>
                        </tr>
                        <tr>
                            <td>6 séances</td>
                            <td><s>280 €</s> 224 €</td>
                            <td><s>190 €</s> 152 €</td>
                            <td><s>220 €</s> 176 €</td>
                        </tr>
                        <tr>
                            <td>12 séances</td>
                            <td><s>530 €</s> 424 €</td>
                            <td><s>350 €</s> 280 €</td>
                            <td><s>390 €</s> 312 €</td>
                        </tr>
                        <tr>
                            <td>18 séances</td>
                            <td><s>760 €</s> 608 €</td>
                            <td><s>500 €</s> 400 €</td>
                            <td><s>550 €</s> 440 €</td>
                        </tr>
                        <tr>
                            <td>24 séances</td>
                            <td><s>980 €</s> 784 €</td>
                            <td><s>635 €</s> 508 €</td>
                            <td><s>700 €</s> 560 €</td>
                        </tr>
                    </table>

                    <table border="1">
                        <tr>
                            <th>Soin minceur enveloppement chaud</th>
                            <td><s>60 €</s> 48 €</td>
                        </tr>
                        <tr>
                            <th>Soin minceur enveloppement froid</th>
                            <td><s>60 €</s> 48 €</td>
                        </tr>
                        <tr>
                            <th>Cure silhouette 8 séances 1h</th>
                            <td><s>480 €</s> 384 €</td>
                        </tr>
                    </table>

                </div>
            </div>
            {/* <div className="slide" id="slide4">
        <div className="text-container">
          <h1>Offre de <em>lancement</em> !</h1>
          <p>
            Profitez de <em>-20%</em> sur votre cure de photo-dépilation ou de photo-rajeunissement du <em>09/01/2023</em> au <em>11/02/2023</em>.
            Un rendez-vous bilan est nécéssaire pour tout vous expliquer. N'hésitez pas, il est gratuit.
          </p>
        </div>
        <img src={Illustration4} alt="Illustration" />
      </div> */}

            <div className="slide" id="slide2">
                <img src={Illustration2} alt="Illustration"/>
                <div className="text-container">
                    <h1>Les pass abonnements de <em>6</em> ou <em>12</em> mois</h1>
                    <p>Le pass est individuel pour une durée de six mois (50,00€) ou un an (80,00€). Il offre 50% de
                        remise sur les épilations classiques et des réductions sur d'autres prestations. Le pass doit
                        être présenté à chaque rendez-vous, sans la carte nous ne pourrons pas appliquer les tarifs
                        préférentiels.</p>
                </div>
            </div>
            <div className="slide" id="slide3">
                <div className="text-container">
                    <h1>Les cartes cadeaux pour <em>faire plaisir</em></h1>
                    <p>
                        Faites plaisir à vos proches, offrez des moments de détente, offrez des cartes cadeaux. Vous
                        pouvez offrir des cartes cadeaux pour une prestation ou pour un montant.<br/>
                        Cliquez <Link to='/offrir'>ici</Link> ou sur le cadeau pour en commander.
                    </p>
                </div>
                <Link to='/offrir'><img src={Illustration3} alt="Illustration"/></Link>
            </div>
        </div>
    )
}